@font-face{
	font-family:gotham-book;
	src:url(../fonts/gotham-book.eot);
	src:url(../fonts/gotham-book.eot?) format("eot"),url(../fonts/gotham-book.woff) format("woff"),url(../fonts/gotham-book.woff2) format("woff2"),url(../fonts/gotham-book.ttf) format("truetype"),url(../fonts/gotham-book.svg#gotham-book) format("svg")
}
@font-face{
	font-family:gotham-bookitalic;
	src:url(../fonts/gotham-bookitalic.eot);
	src:url(../fonts/gotham-bookitalic.eot?) format("eot"),url(../fonts/gotham-bookitalic.woff) format("woff"),url(../fonts/gotham-bookitalic.woff2) format("woff2"),url(../fonts/gotham-bookitalic.ttf) format("truetype"),url(../fonts/gotham-bookitalic.svg#gotham-bookitalic) format("svg")
}
@font-face{
	font-family:gotham-light;
	src:url(../fonts/gotham-light.eot);
	src:url(../fonts/gotham-light.eot?) format("eot"),url(../fonts/gotham-light.woff) format("woff"),url(../fonts/gotham-light.woff2) format("woff2"),url(../fonts/gotham-light.ttf) format("truetype"),url(../fonts/gotham-light.svg#gotham-light) format("svg")
}
@font-face{
	font-family:gotham-medium;
	src:url(../fonts/gotham-medium.eot);
	src:url(../fonts/gotham-medium.eot?) format("eot"),url(../fonts/gotham-medium.woff) format("woff"),url(../fonts/gotham-medium.woff2) format("woff2"),url(../fonts/gotham-medium.ttf) format("truetype"),url(../fonts/gotham-medium.svg#gotham-medium) format("svg")
}
@font-face{
	font-family:helvetica-neue;
	src:url(../fonts/helvetica-neue.eot);
	src:url(../fonts/helvetica-neue.eot?) format("eot"),url(../fonts/helvetica-neue.woff) format("woff"),url(../fonts/helvetica-neue.woff2) format("woff2"),url(../fonts/helvetica-neue.ttf) format("truetype"),url(../fonts/helvetica-neue.svg#helvetica-neue) format("svg")
}
@font-face{
	font-family:helvetica-neue-bold;
	src:url(../fonts/helvetica-neue-bold.eot);
	src:url(../fonts/helvetica-neue-bold.eot?) format("eot"),url(../fonts/helvetica-neue-bold.woff) format("woff"),url(../fonts/helvetica-neue-bold.woff2) format("woff2"),url(../fonts/helvetica-neue-bold.ttf) format("truetype"),url(../fonts/helvetica-neue-bold.svg#helvetica-neue-bold) format("svg")
}




body, html {
}

body {
  margin: 0;
  padding: 0;
  font: $font-weight-regular normal 16px/16px $font-main;
 @include fontsmoothing;
  color: $color-primary;
  background-color: $color-white;
  @include mq-md {
    font-size: 15px;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-main;
  text-transform: none;
  font-weight: normal;
  line-height: 1em;
}

h1 {
  font-size: 2.25em;
}

h2 {
  font-size: 1.875em;
}

h3 {
  font-size: 1.5em;
}

h4 {
  font-size: 1.25em;
}

h5 {
  font-size: 1.125em;
}

h6 {
  font-size: 1em;
}

ul {

}

li {

}

p {
  font-size: 1.125em;
}

p.small {
  font-size: 0.75em;
}

a:link, a:visited {
  text-decoration: none;
  color: $color-primary;
  @include transition(color 0.5s ease);
}

a:hover, a:active {
  color: $color-black;
}

strong {
  font-weight: 600;
}


#p11-intero-container {
  width: 100%;
  overflow: hidden;


/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  GLOBAL
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/


/* Fonts */

.font-main {
  font-family: $font-main;
  font-weight: $font-weight-regular
}

.font-main-bold {
  font-family: $font-main-bold;
  font-weight: $font-weight-bold
}

.font-main-light {
  font-family: $font-main-light;
  font-weight: $font-weight-light
}

.font-headline {
  font-family: $font-headline;
  font-weight: $font-weight-regular
}

.font-headline-bold {
  font-family: $font-headline-bold;
  font-weight: $font-weight-bold
}

.font-headline-light {
  font-family: $font-headline-light;
  font-weight: $font-weight-light
}

/* BG Color */

.bgwhite {
  background-color: $color-white;
}

.bgprimary {
  background-color: $color-primary;
}

.bgsecondary {
  background-color: $color-secondary;
}

.bgtertiary {
  background-color: $color-tertiary;
}

.bgtertiary-dark {
  background-color: $color-tertiary-dark;
}

.bgtertiary-light {
  background-color: $color-tertiary-light;
}

.bgsurrogate {
  background-color: $color-surrogate;
}

/* Text Color */

.twhite {
  color: $color-white !important;
}

.tprimary {
  color: $color-primary;
}

.tsecondary {
  color: $color-secondary;
}

.ttertiary {
  color: $color-tertiary;
}

.tsurrogate {
  color: $color-surrogate;
}

::selection {
    background-color: $color-surrogate;
    color: $color-white;
}

::-moz-selection {
   background-color: $color-surrogate;
    color: $color-white;
}

::-o-selection {
    background-color: $color-surrogate;
    color: $color-white;
}

::-ms-selection {
    background-color: $color-surrogate;
    color: $color-white;
}

::-webkit-selection {
    background-color: $color-surrogate;
    color: $color-white;
}

.cleanlist {
    margin: 0 0 1em;
    padding: 0;
}

.cleanlist h2 {
  display: block;
  font-size: 1em;
  font-weight: 500;
  text-align: center;
   margin: 0 0 0.5em -15px;
   padding: 0;
}

.cleanlist li {
    font-size: 1.063em;
    line-height: 1.125em;
    margin: 0 0 0.5em;
    padding: 0.25em 0;
}
.cleanlist li ul {
    margin: 1em 0 0 1em;
    padding: 0;
}
.cleanlist li ul li {
    font-size: 1.063em;
    line-height: 1.125em;
    margin: 0 0 0.5em;
    padding: 0.25em 0;
}

.boxheight {
  height: 80vh;
  min-height: 500px;

  @include mq-md {
    height: auto;
    min-height: none;
  }

}

.fadecover {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background-color: fn-transparent-color($color-black , 0.5);
}

#uplink {
  display: none;
  position: fixed;
  right: 2em;
  bottom: 2em;
  z-index: 2000;
  background-color: fn-transparent-color($color-black , 0.7);
}

#uplink:hover {
  background-color: #000;
}

.boxbtn:link, .boxbtn:visited {
  text-transform: uppercase;
  display: block;
  font-size: 1.25em;
  line-height: 1.75em;
  text-align: center;
  text-decoration: none;
  margin: 0;
  padding: .35em;
  position: relative;
  color: $color-tertiary;
  border: 1px solid $color-tertiary;
  background-color: fn-transparent-color($color-secondary, 0);
  @include transition(background-color 0.5s ease, border-color 0.5s ease);

  &:hover {
    color: $color-secondary;
    background-color: fn-transparent-color($color-secondary, 0.05);
    border-color: $color-secondary;
  }
}

// .boxbtn:link, .boxbtn:visited {
//   text-transform: uppercase;
//   display: block;
//   font-size: 1.25em;
//   line-height: 1.75em;
//   text-align: center;
//   text-decoration: none;
//   margin: 0;
//   padding: .35em;
//   position: relative;
//   color: $color-tertiary;
//   @include transition(color 0.5s ease);
//
//   span {
//     display: block;
//     position: absolute;
//     top: 0;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     border: 1px solid $color-tertiary;
//     @include transition(transform 0.5s ease, webkit-transform 0.5s ease);
//
//     -webkit-transition: -webkit-transform 0.5s ease-in-out;
//     -moz-transition: -moz-transform 0.5s ease-in-out;
//     transition: transform 0.5s ease-in-out;
//   }
//
//   &:hover {
//     color: $color-secondary;
//
//     span {
//       transform: skew(-30deg, 0);
//     }
//   }
// }

.default-content {

	p {
		margin-bottom: 1em;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-bottom: 0.75em;
	}

	ul {
		margin: 0;
		padding: 0 2em 1.25em 0;
		list-style: none;
		margin-left: 0;
		padding-left: 2em;
		text-indent: -1.125em;

		li {
			margin: 0px 0px 15px 0px;
			padding: 0px 0px 0px 0px;
			font-size: 1.125rem;
			line-height: 1.5rem;
			text-align: left;

			&:before {
				content: '• ';
				color: $color-black;
				padding-right: 0.25rem;
				font-size: 1rem;
			}
		}
		ul {
			margin: 0;
			padding: 0 2em 0 2em;
			list-style: none;
			margin-left: 0;
			padding-left: 2em;
			text-indent: -1.75em;

			li {
				margin: 0px 0px 10px 0px;
				padding: 0px 0px 0px 0px;
				font-size: 1rem;
				line-height: 1rem;

				&:before {
					content: ' ';
					color: $color-black;
					padding-right: 1em;
				}
			}
		}
	}
}

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  STRUCTURE
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

header, footer, section {
  clear: both;
  width: 100%;
  position: relative;
}

.wrapper, .wrapper-full, .wrapper-wide {
    max-width: 980px;
    width: 95%;
    margin: 0 auto;
    position: relative;
}

.wrapper-full {
   width: 100%;
}

.wrapper-full {
   max-width: 1200px;
}

.responsive-background-image {
   background-size: cover !important;
   background-position: top !important;
   img {
      display: none;
   }
}

// .container {
//   width: 100%;
//   overflow-x: hidden;
// }


//** SECNAV **/

.secnav {
	display:flex;
  align-items: stretch;
  justify-content: flex-start;
	text-align: center;

	@include mq-md {
		margin-left: 0;
	}

	li {
		margin: 5px 5px 5px 5px;
		display:flex;
    flex-grow:1;
    align-items:center;
    flex-direction: column;

		@include mq-sm {
			width: calc(100% / 2);
		}

		a {
			display: block;
			font-size: 1.25rem;
      line-height: 1.125em;
			text-align: center;
			text-decoration: none;
			margin: 0;
			padding: 1em;
			position: relative;
			text-transform: uppercase;
			color: $color-white;
			width: 100%;
			align-content: center;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      @include transition(opacity 0.5s ease);

			&:hover,
			&:active {
				opacity: 0.5;
			}

			&.active {
				opacity: 0.5;
			}
		}

		&.active > a {
			opacity: 0.5;
		}
	}

	&.navspan2 li {
		width: calc(100% / 2);
	}

	&.navspan4 li {
		width: calc(100% / 4);
	}

	&.navspan5 li {
		width: calc(100% / 5);

		@include mq-xlg {
			width: calc(100% / 3);
		}

		@include mq-md {
			width: calc(100% / 2);
		}

    @include mq-sm {
      width: 100%;
    }
	}

	&.navspan6 li {
		width: calc(100% / 6 );

    a {
      font-size: 1.125em;
    }

    @include mq-xlg {
			width: calc(100% / 3);
		}

		@include mq-md {
			width: calc(100% / 2);
		}

    @include mq-sm {
      width: 100%;
    }
	}
}

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  HEADER
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
#top-logo {
  position: absolute;
  top: 20px;
  left: 25px;
  width: 159px;
  height: auto;
}

header {
  background-color: $color-black;
  width: 100%;
  height: 90px;
  position: fixed;
  z-index: 2000;
  top: 0;

  #top-logo {
    position: absolute;
    top: 20px;
    left: 25px;
    width: 160px;
    height: auto;

    @include mq-sm {
      top: 25px;
      width: 130px;
    }
  }

  #backtointero {
    font-family: $font-main;
    text-transform: uppercase;
    position: absolute;
    top: 30px;
    right: 20px;
    color: $color-white;
    font-size: 1em;
    width: 35%;
    text-align: right;
    line-height: 1.25em;

    @include mq-sm {
      top: 25px;
    }
  }
}


#slider  {
	display: inherit;
	position: relative;
	width: 100%;
	height: 400px;
	background-color: $color-black;
	overflow: hidden;
	z-index: 0;
  margin-top: 90px;

  .triangle {
    position: absolute;
    z-index: 200;
    width: 200px;
    height: 400px;

    @include mq-md {
      width: 100px;
      height: 200px;
    }
  }
  .triangle-left {
    left: 0;
    top: 0;
    border-top: solid 200px fn-transparent-color($color-black, .5);
    border-left: solid 100px fn-transparent-color($color-black, .5);
    border-right: solid 100px transparent;
    border-bottom: solid 200px transparent;

    @include mq-md {
      border-top: solid 100px fn-transparent-color($color-black, .5);
      border-left: solid 50px fn-transparent-color($color-black, .5);
      border-right: solid 50px transparent;
      border-bottom: solid 100px transparent;
    }
  }
  .triangle-right {
    right: 0;
    bottom: 0;
    border-bottom: solid 200px fn-transparent-color($color-black, .5);
    border-right: solid 100px fn-transparent-color($color-black, .5);
    border-left: solid 100px transparent;
    border-top: solid 200px transparent;

    @include mq-md {
      border-bottom: solid 100px fn-transparent-color($color-black, .5);
      border-right: solid 50px fn-transparent-color($color-black, .5);
      border-left: solid 50px transparent;
      border-top: solid 100px transparent;
    }
  }

//   #slider-grad-bottom {
//     position: absolute;
//     width: 100%;
//     height: 200px;
//     bottom: 0;
//     z-index: 300;
//     background: 0 0;
//     background: -moz-linear-gradient(top,transparent 0,rgba(0,0,0,.75) 100%);
//     background: -webkit-linear-gradient(top,transparent 0,rgba(0,0,0,.75) 100%);
//     background: linear-gradient(to bottom,transparent 0,rgba(0,0,0,.75) 100%);
// }

  .overlay {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 400;
    background-color: fn-transparent-color($color-black, 0.3)
  }

  #slider-logo {
    display: block;
    width: 100%;
    @include centerall();
    z-index: 500;
    text-align: center;

    #logo {
      max-width: 575px;
      width: 90%;
    }

  }

  .slider-nav {
  	display:flex;
    align-items: stretch;
    justify-content: flex-start;
  	text-align: center;
    margin-top: 10vh;

    @include mq-md {
      margin-top: 2em;
    }

    // @include mq-sm {
    //   display: block;
    // }

  	li {
  		margin: 5px 5px 5px 5px;
  		display:flex;
      flex-grow:1;
      align-items:center;
      flex-direction: column;

      // @include mq-sm {
      //   display: block;
      //   width: 100%;
      // }

  		a {
  			display: inline-block;
        width: auto;
        background-color:  fn-transparent-color($color-black, 0.75);
  			font-size: 1.25rem;
  			text-align: center;
  			text-decoration: none;
  			margin: 0;
  			padding: 1em;
  			position: relative;
  			text-transform: uppercase;
  			color: $color-white;
  			align-content: center;
        @include transition(opacity 0.5s ease);

        @include mq-md {
          font-size: 1em;
        }

        @include mq-sm {
          font-size: 0.75em;
        }

        h2, h3 {
          color: $color-white;
          line-height: 1.25em;
        }

        h2 {
          font-weight: 900;
        }

        h3 {
          margin: 0;

          // @include mq-sm {
          //   br { content: "";}
          //   br:after { content: " ";}
          // }
        }

  			&:hover,
  			&:active {
  				opacity: 0.75;
  			}

  			&.active {
  				opacity: 0.75;
  			}
  		}

  		&.active > a {
  			opacity: 0.75;
  		}
  	}

  }



  // h1, h2 {
  //   font-family: $font-headline;
  //   font-weight: $font-weight-regular;
  //   font-size: 4.25em;
  //   color: $color-white;
  //   @include center-horz();
  //   bottom: 1.75em;
  //   z-index: 500;
  //   // @include text-shadow(2px 2px 2px rgba(0,0,0,.5));
  //   display: block;
  //   width: 100%;
  //   text-align: center;
  //
  //   @include mq-lg {
  //     font-size: 3.5em;
  //   }
  //
  //   @include mq-md {
  //     font-size: 3em;
  //     bottom: .75em;
  //   }
  //
  //   @include mq-sm {
  //     font-size: 2em;
  //   }
  // }
  //
  // h1 {
  //
  // }

  @keyframes animate-home-arrow {
		0% {
			margin-top: 0;
		}
		100% {
			margin-top: .5em;
		}
	}

	#down-arrow {
    position: absolute;
		left: 0;
		bottom: 0;
    height: 50px;
    width: 100%;
    z-index: 500;
    background-color: $color-secondary;
    @include transition(background-color 0.5s ease);
    // @include svg-shadow(2px 2px 2px rgba(0,0,0,.5));

    @include mq-md {
      display: none!important;
    }

    img {
      @include center-horz();
      top: 12px;
      animation: animate-home-arrow 0.75s linear 0s infinite alternate;
      width: 35px;
      height: auto;
    }

		&:hover {
      background-color: $color-secondary-dark;
      img {
        animation-play-state: paused;
      }
		}

	}

	@include mq-md {
		height: 400px !important;
	}

	@include mq-sm {
		height: 300px !important;
	}


	div.slide {
		width: 100%;
		height: 100%;
    position: relative;
    overflow: hidden;
    z-index: 500;


		@include mq-md {
			height: 100%;
			background-position: center 0 !important;
		}

    div {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      overflow: hidden;
      @include transition(transform 90s);
    }

    // &.cycle-slide-active > div {
    //   z-index: 600;
    //   -moz-transform: scale(2,2);
    //   -webkit-transform: scale(2,2);
    //   transform: scale(2,2);
    //   @include transition(transform 45s);
    // }

    &.parallax-rev {
      height: 107%;
      -ms-transform: translateY(-25px); /* IE 9 */
    	-webkit-transform: translateY(-25px); /* Chrome, Safari, Opera */
    	transform: translateY(-25px);

      @include mq-md {
        height: 100%;
        -ms-transform: translateY(0); /* IE 9 */
      	-webkit-transform: translateY(0); /* Chrome, Safari, Opera */
      	transform: translateY(0);
      }
    }
	}
}

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  HOME PAGE
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

&.home,
&.pinnacle-forward {

  #slider {
    height: 80vh;

    #down-arrow {
			display: block;
		}
  }

  #intro {
    padding: 8em 1em;

    @include mq-md {
      padding: 6em 1em;
    }

    @include mq-sm {
      padding: 3em 1em;
    }

    .intro-logo {
      width: 85%;
      max-width: 350px;
      margin-bottom: 2em;
    }

    #intro-contact {
      width: 90%;
      margin: 2em auto 0;
      max-width: 300px;
    }

  	h1 {
			font-size: 2.25em;
	    line-height: 1.57em;
	    display: block;

      @include mq-sm {
        font-size: 1.75em;
      }
		}

		p {
      margin-top: .5em;
			line-height: 1.75em;
		}

	}
}

.text-block {
  padding: 8em 1em;

  @include mq-md {
    padding: 6em 1em;
  }

  @include mq-sm {
    padding: 3em 1em;
  }

  h2 {
    margin-bottom: .75em;
  }
}

.amenities-list {
  margin: 0;
  //padding: 1.5em 0 0 0;
  list-style: none;
  margin-left: 0;
  padding-left: 1.25em;
  text-indent: -1.75em;

	@include mq-md {
	  margin-top: 15px;

		&:first-of-type {
	    margin-top: 0;
	  }
	}

	li {
    margin: 0 0 15px 0;
    padding: 0;
    font-size: 1.125em;
    line-height: 1.25em;
    text-align: left;

		&:before {
			content: '▲ ';
			color: $color-white;
			padding-right: 0.25em;
			line-height: 1;
		}

		&:last-of-type {
			margin-bottom: 0;
		}

		ul {
			margin: 10px 0;
	    padding: 0;
	    list-style: none;
	    margin-left: 0;
	    padding-left: 0;
	    text-indent: 0;

			li {
				margin: 0 0 10px 0;
		    padding: 0;
		    font-size: 1em;
		    line-height: 1.25em;

				&:before {
					content: '';
					color: #fff;
					padding-right: 0;
				}
			}
		}

		a {
			color: $color-white;
			opacity: 1;

			&:hover {
				color: $color-secondary;
			}
		}

	}

}


.numbered-list {
  margin: 0 0 0.75em 1.25em;

	li {
    margin: 0 0 15px 0;
    padding: 0;
    font-size: 1.125em;
    line-height: 1.25em;
    text-align: left;

		&:last-of-type {
			margin-bottom: 0;
		}

		a {
			color: $color-white;
			opacity: 1;

			&:hover {
				color: $color-secondary;
			}
		}

	}

}

.homebox-a {
  padding: 8em;

  @include mq-md-lg {
    padding: 8em 3em;
  }

  @include mq-md {
    padding: 1em;
  }

  @include mq-tablet-landscape {
    height: auto!important;
  }

  .homebox-a-content {
    @include centerall();
    overflow: hidden;
    position: relative;
    width: 100%;
    max-width: 980px;
    padding: 8em;
    // margin: 0 auto;
    background-color: fn-transparent-color($color-primary, 0.75);

    @include mq-md {
      text-align: center;
      padding: 4em;
      @include centernone();
    }

    @include mq-tablet-landscape() {
      @include centernone;
    }

    p {
      margin-bottom: 1em;
    }

    .triangle {
      position: absolute;
      z-index: 200;
      width: 120px;
      height: 160px;
      @include transition(opacity 1s ease, top 1s ease, right 1s ease, bottom 1s ease, left 1s ease);
    }
    .triangle-left {
      left: -40px;
      top: -40px;
      opacity: 0;
      border-top: solid 80px $color-secondary;
      border-left: solid 60px $color-secondary;
      border-right: solid 60px transparent;
      border-bottom: solid 80px transparent;

      &.in-view {
        left: 0;
        top: 0;
        opacity: 1;
      }
    }
    .triangle-right {
      right: -40px;
      bottom: -40px;
      opacity: 0;
      border-bottom: solid 80px $color-secondary;
      border-right: solid 60px $color-secondary;
      border-left: solid 60px transparent;
      border-top: solid 80px transparent;

      &.in-view {
        right: 0;
        bottom: 0;
        opacity: 1;
      }
    }
  }
}

.homebox-b {
  padding: 5em 5%;
  overflow: hidden;

  @include mq-md {
    padding: 0;
  }

  .homebox-b-content {
    @include center-vert();
    padding: 5vh 15vw 5vh 8vw;
    position: relative;
    z-index: 100;
    width: 45%;

    @include mq-xxlg {
      padding: 5vh 15vw 5vh 0vw;
    }

    @include mq-lg {
      width: 55%;
    }

    @include mq-md {
      text-align: center;
    }

    @include mq-md {
      @include centernone();
      background-color: $color-surrogate;
      width: 100%;
      padding: 5em 3em;
    }

    // @include mq-tablet-landscape() {
    //   height: auto!important;
    // }

    @-webkit-keyframes gearspin {
      0% { transform: rotate(0); }
      100% { transform: rotate(360deg); }
    }

    #renovation-icon {
      width: 195px;
      height: 115px;
      margin: 0 auto 2em;
      position: relative;
      opacity: 0;
      @include transition(opacity 0.5s ease);

      // @include mq-md {
      //   margin: 0 0 2em;
      // }

      img {
        position: absolute;
        width: 115px;
        height: auto;
        left: 0;
        top: 0;

        &.gear {
          width: 55px;
          height: 55px;
          left: 42px;
          top: 16px;
        }
      }

      &.in-view {
        opacity: 1;

        img.gear {
          animation: gearspin 2s 4 linear;
          -webkit-animation: gearspin 5s 2 linear;
        }

      }
    }

    h2 {
      margin: 0;
      font-weight: $font-weight-bold;
    }

    h3 {
      font-size: 1.125em;
      line-height: 1.25em;
      font-family: $font-main-bold;
      margin: .5em 0 1.5em;
    }

    .amenities-list {

      li {
        &:before {
          color: $color-secondary;
        }

        @include mq-md {
          text-align: center;
        }
      }
    }
  }

  .homebox-b-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 65%;

    @include mq-md {
      @include centernone();
      width: 100%;
      height: 400px;
    }
  }

  .triangle, .triangle-shadow {
    position: absolute;
    z-index: 50;
    width: 50vw;
    height: 200vw;
    top: 0;
    left: 0;
    border-top: solid 100vw $color-surrogate;
    border-left: solid 25vw $color-surrogate;
    border-right: solid 25vw transparent;
    border-bottom: solid 100vw transparent;

    @include mq-lg {
      width: 60vw;
    }

    @include mq-md {
      display: none;
    }
  }

  .triangle-shadow {
    top: 6em;
    z-index: 25;
    border-top: solid 100vw fn-transparent-color($color-primary, 0.5);
    border-left: solid 25vw fn-transparent-color($color-primary, 0.5);
  }

}

.homebox-c {
  padding: 5em;
  overflow: hidden;

  .overlay {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    background-color: fn-transparent-color($color-black, 0.5)
  }

  .homebox-c-content {
    @include centerall();
    padding: 5vh 2vw;
    position: relative;

    @include mq-md {
      @include centernone();
    }

    @-webkit-keyframes brushmove {
      0% { transform: translateY(0); }
      50% { transform: translateY(-20px); }
      100% { transform: translateY(0); }
    }

    #cosmetic-icon {
      width: 115px;
      height: 115px;
      margin: 0 auto 2em;
      position: relative;
      opacity: 0;
      @include transition(opacity 0.5s ease);

      img {
        position: absolute;
        width: 115px;
        height: auto;
        left: 0;
        top: 0;
      }

      &.in-view {
        opacity: 1;
        animation: brushmove 2s 4  ease-in-out;
        -webkit-animation: brushmove 2s 4  ease-in-out;
      }
    }

    h2 {
      margin: 0;
      font-weight: $font-weight-bold;
    }

    h3 {
      font-size: 1.125em;
      line-height: 1.25em;
      margin: .5em 0 1.5em;
      font-family: $font-main-bold;
    }

    .amenities-list {
      margin: 0 auto;
      display: table;

      li {
        color: $color-white;

        @include mq-md {
          text-align: center;
        }
      }
    }
  }

}

.homebox-d {
  padding: 5em 5%;
  overflow: hidden;

  @include mq-md {
    padding: 0;
  }

  .homebox-d-content {
    @include center-vert();
    padding: 5em 0 5em 15%;
    position: relative;
    z-index: 100;
    width: 45%;

    @include mq-lg {
      width: 55%;
      padding: 5em 0 5em 17%;
    }

    @include mq-md {
      @include centernone();
      float: none;
      background-color: $color-tertiary;
      width: 100%;
      padding: 5em 3em;
      text-align: center;
    }

    @-webkit-keyframes starsgrow {
      0%  { -webkit-transform: scale(.5) }
    100% {-webkit-transform: scale(1) }
    }


    #elite-icon {
      width: 150px;
      height: 70px;
      margin: 0 0 2em 2em;
      position: relative;
      opacity: 0;
      @include transition(opacity 0.5s ease);

      @include mq-md {
        margin: 0 auto 2em;
      }

      img {
        position: absolute;
        width: 150px;
        height: auto;
        left: 0;
        top: 0;
      }

      &.in-view {
        opacity: 1;
        animation: starsgrow 1.25s 1 linear;
        -webkit-animation: starsgrow 1.25s 1 ease-in-out;

      }
    }

    h2 {
      margin: 0;
      font-weight: $font-weight-bold;
    }

    h3 {
      font-size: 1.125em;
      line-height: 1.25em;
      margin: .5em 0 1.5em;
      font-family: $font-main-bold;
    }

    .amenities-list {

      li {
        color: $color-white;
        &:before {
          color: $color-surrogate;
        }

        @include mq-md {
          text-align: center;
        }
      }
    }
  }

  .homebox-d-image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 65%;
    // -moz-transform: scaleX(-1);

    @include mq-md {
      @include centernone();
      width: 100%;
      height: 400px;
    }
  }

  .triangle, .triangle-shadow {
    position: absolute;
    z-index: 50;
    width: 50vw;
    height: 200vw;
    top: 0;
    right: 0;
    border-top: solid 100vw $color-tertiary;
    border-left: solid 25vw $color-tertiary;
    border-right: solid 25vw transparent;
    border-bottom: solid 100vw transparent;
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
    -ms-filter: fliph; /*IE*/
    filter: fliph; /*IE*/

    @include mq-lg {
      width: 60vw;
    }

    @include mq-md {
      display: none;
    }
  }

  .triangle-shadow {
    top: 6em;
    z-index: 25;
    border-top: solid 100vw fn-transparent-color($color-surrogate, 0.75);
    border-left: solid 25vw fn-transparent-color($color-surrogate, 0.75);
  }

}

.homebox-e {

  .half {
    position: relative;
    overflow: hidden;

    @include mq-md {
      height: auto;
    }

    @include mq-tablet-landscape {
      width: 100%;
      height: auto;
    }

    .triangle {
      position: absolute;
      z-index: 0;
      width: 50vw;
      height: 200vw;
      bottom: 5em;
      right: 0;
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 150vh 50vw;
      border-color: transparent transparent $color-primary transparent;
      opacity: 0.75;

      @include mq-md-lg {
        border-width: 0 0 150vh 60vw;
      }

      @include mq-md {
        // @include centernone();
        display: none;
      }
    }

    .cntrtxt {
      @include centerall();
      width: 25vw;
      max-width: 500px;
      text-align: center;
      margin-left: 7vw;
      padding-left: 2em;

      @include mq-xlg {
        margin-left: 5vw;
        width: 35vw;
      }

      // @include mq-lg {
      //   width: 100%;
      // }

      @include mq-md-lg {
        width: 35vw;
      }

      @include mq-md {
        @include centernone;
        width: 100%;
        max-width: 100%;
        margin-left: 0;
        padding: 6em 3em;
        background: fn-transparent-color($color-primary, 0.75);
      }

      @include mq-tablet-landscape {
        @include centernone;
        width: 100%;
        max-width: 100%;
        margin-left: 0;
        padding: 6em 3em;
        background: fn-transparent-color($color-primary, 0.75);
      }
    }

    &.right {
      .triangle {
        right: auto;
        left: 0;
        bottom: auto;
        top: 5em;
        border-color: transparent transparent $color-surrogate transparent;
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);

        @include mq-md {
          display: none;
          // background: $color-surrogate;
        }

        @include mq-tablet-landscape {
          display: none;
        }
      }
      .cntrtxt {
        margin-left: -7vw;

        @include mq-xlg {
          margin-left: -8vw;
          width: 35vw;
        }

        // @include mq-lg {
        //   width: 100%;
        // }

        @include mq-md {
          width: 100%;
          background: fn-transparent-color($color-surrogate, 0.75);
          margin-left: 0;
        }

        @include mq-tablet-landscape {
          width: 100%;
          background: fn-transparent-color($color-surrogate, 0.75);
          margin-left: 0;
        }
      }
    }

  }
}

#ba-intro {
  padding-bottom: 2em;
}

#ba-slider {
  width: 80%;
  margin: 0 auto 6em;

  @include mq-md {
    width: 90%;
    margin-bottom: 3em;
  }

  .cycle-prev, .cycle-next {
    display: inline-block;
    margin: 1em;
    font-size: 2em;
    line-height: .9em;
    color: $color-tertiary;
    text-transform: uppercase;
    @include transition(color 0.5s ease);

    @include mq-md {
      font-size: 1.5em;
    }

    img {
      display: inline-block;
      width: 40px;
      height: 27px;
      vertical-align: top;
      padding: 0 .25em;

      @include mq-md {
        width: 25px;
        height: 19px;
      }
    }

    &:hover {
      cursor: pointer;
      color: $color-secondary;
    }
  }

  .cocoen {
    width: 100%;
    img {
      width: 100%;
      height: auto;
      cursor: default;
    }
    .cocoen-drag {
      width: 3px;
      @include grab-cursor;
      background-color: $color-black;

      &:before {
        background-color: $color-white;
        border: 3px solid $color-black;
        width: 60px;
        height: 60px;
        border-radius: 30px;
        margin-left: -30px;
        margin-top: -30px;
        background-image: url('/images/home/ba-arrows.svg');
        background-position: center;
        background-size: 50%;
        background-repeat: no-repeat;
      }

      &:active {
        @include grabbing-cursor;
      }

    }
  }
}

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  PINNACLE FORWARD
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

#faq {
  padding: 8em 0 0;
}

#offer-icon {
  width: 115px;
  height: 125px;
  margin: 0 0 2em 2em;
  position: relative;
  opacity: 0;
  @include transition(opacity 0.5s ease);

  @include mq-md {
    margin: 0 auto 2em;
  }

  img {
    position: absolute;
    width: 100%;
    height: auto;
    left: 0;
    top: 0;

    &.paper {
      left: -1em;
      @include transition(left 0.5s ease);
    }

    &.award {
      top: 1em;
      opacity: 0;
      @include transition(top 0.5s ease 0.25s, opacity 0.5s ease 0.25s);
    }

    &.pen {
      left: 1em;
      opacity: 0;
      @include transition(left 0.5s ease 0.5s, opacity 0.5s ease 0.5s);
    }
  }

  &.in-view {
    opacity: 1;
    img {

      &.paper {
        left: 0;
      }

      &.award {
        top: 0;
        opacity: 1;
      }

      &.pen {
        left: 0;
        opacity: 1;
      }
    }

  }
}

@-webkit-keyframes keysrotate {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(-30deg); }
  50% { transform: rotate(15deg); }
  75% { transform: rotate(-15deg); }
  100% { transform: rotate(0deg); }
}

#showings-icon {
  width: 115px;
  height: 125px;
  margin: 0 auto 2em;
  position: relative;
  opacity: 0;
  @include transition(opacity 0.5s ease);

  @include mq-md {
    margin: 0 auto 2em;
  }

  img {
    position: absolute;
    width: 100%;
    height: auto;
    left: 0;
    top: 0;
  }

  &.in-view {
    opacity: 1;
    animation: keysrotate 2s 1 ease-in-out;
    -webkit-animation: keysrotate 2s 1 ease-in-out;

    img {
    }

  }
}

@-webkit-keyframes signswing {
  0% { transform: scaleY(1) translateY(0); }
  25% { transform: scaleY(0.8) translateY(-.5em); }
  50% { transform: scaleY(1) translateY(0); }
  75% { transform: scaleY(0.9) translateY(-0.25em); }
  100% { transform: scaleY(1) translateY(0); }
}

#sell-icon {
  width: 115px;
  height: 125px;
  margin: 0 0 2em 2em;
  position: relative;
  opacity: 0;
  @include transition(opacity 0.5s ease);

  @include mq-md {
    margin: 0 auto 2em;
  }

  img {
    position: absolute;
    width: 100%;
    height: auto;
    left: 0;
    top: 0;
  }

  &.in-view {
    opacity: 1;

    img {

      &.sign {
        animation: signswing 2.5s 1 ease-in-out;
        -webkit-animation: signswing 2.5s 1 ease-in-out;
      }

    }

  }
}


#double-icon {
  width: 115px;
  height: 125px;
  margin: 0 auto 2em;
  position: relative;

  @include mq-md {
    margin: 0 auto 2em;
  }

  img {
    position: absolute;
    width: 100%;
    height: auto;
    left: 0;
    top: 0;

    &.dollar1 {
      left: 10%;
      @include transition(opacity 0.75s ease-out,left 0.75s ease-out);
    }

    &.dollar2 {
      left: -10%;
      @include transition(opacity 75s ease-out,left 0.75s ease-out);
    }

    &.nosign {
      opacity: 0;
      transform: scale(150%);
      @include transition(opacity 0.75s ease-out,transform 0.75s ease-out);
    }
  }

  &.in-view {
    opacity: 1;

    img {

      &.dollar1 {
        opacity: 1;
        left: 0;
      }

      &.dollar2 {
        opacity: 1;
        left: 0;
      }

      &.nosign {
        opacity: 1;
        transform: scale(100%);
        @include transition(opacity 01s 0.25s ease-out,transform 0.75s 0.25s ease-out);
      }


    }

  }
}

#rates-icon {
  width: 90px;
  height: 145px;
  margin: 0 0 2em 2em;
  position: relative;

  @include mq-md {
    margin: 0 auto 2em;
  }

  img {
    position: absolute;
    width: 100%;
    height: auto;
    left: 0;
    top: 0;

    &.hand {
      opacity: 0;
      left: 2em;
      @include transition(opacity 1s ease,left 1s ease-out);
    }

    &.house {
      opacity: 0;
      @include transition(opacity 2s 0s ease);
    }

  }

  &.in-view {

    img {

      &.hand {
        opacity: 1;
        left: 0;
      }

      &.house {
        opacity: 1;
        @include transition(opacity 2s 0.9s ease);
      }


    }

  }
}


/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  FOOTER
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

div.nav-bar {
  border: none;
  height: 90px;
  // border-bottom:1px solid #eee;
	z-index:5002;
	min-width:320px;
  z-index: 5000;

  .nav.navbar-nav.top-level-nav {


    // @include mq-sm {
    //   width: 50%!important;
    //   text-align: right;
    // }

    @include mq-md {
      display: none;
    }

    .sub-arrow, .collapsible .sub-arrow {
    position: static;
    margin-top: 0;
    margin-right: 0;
    margin-left: 6px;
    display: inline-block;
    width: 0;
    height: 0;
    overflow: hidden;
    vertical-align: middle;
    border-top: 4px solid;
    border-right: 4px dashed transparent;
    border-bottom: 4px dashed transparent;
    border-left: 4px dashed transparent;
}

    li {

      a {
        text-transform: uppercase;
        color: $color-white;
        padding: 0;
        // margin: 35px 2em 0 15px;
        margin: 35px 15px 0 15px;
        line-height: normal;
        padding-bottom: 7px;
        -webkit-transition: all .25s ease;
        -moz-transition: all .25s ease;
        -ms-transition: all .25s ease;
        -o-transition: all .25s ease;
        transition: all .25s ease;
        border-bottom: 2px solid #000;
        background: none!important;
        white-space: normal;

        @include mq-sm {
          padding-right: 15px;
          padding-top: 25px;
        }

        &:hover {
          border-bottom: 2px solid #919191;
        }
      }

      &.more {
        a {
          text-transform: none;
          margin: 34px 10px 0 10px;
        }

        ul {
          width: auto;
          top: auto;
          left: 0px;
          margin-left: -72.7656px;
          margin-top: 10px;
          min-width: 11em;
          max-width: 18em;

          li {

            a {
              color: $color-black;
              border: none;
              margin: 0;
              padding: 16.5px 15px;
              margin: 0;

              &:hover {
                background-color: #f5f5f5!important;
              }
            }
          }
        }
      }

      &.current {
        a {
          border-bottom: 2px solid #919191;
        }
      }
    }
  }
}

.top-level-nav-mobile {
  display: none;
  // min-height: 500px;

  @include mq-md {
    display: block;
  }

  @include mq-tablet-landscape {
    width: 100%;
  }

  button {
    position: absolute;
    right: 0;
    font-size: 35px;
    top: 25px;
    height: 33px;
    padding-right: 64px;
    background: none;
    color: white;
  }

  ul.navbar-nav {
    position: absolute;
    z-index: 5000;
    right: 0!important;
    top: 75px!important;
    margin-right: 64px;
    border: 1px solid rgba(0,0,0,.15);
    -moz-border-radius: 4px 4px 4px 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0,0,0,.175);

    @include mq-tablet-landscape {
      width: 100%;
    }

    li {
      float: none;

      @include mq-tablet-landscape {
        display: block;
        width: 50%;
        float: left;
      }

      a {
        font-size: 14px;
        display: block;
        padding: 15px;
        font-family: $font-main;
        background-color: $color-white;
        // @include transition(background-color 0.5s ease);

        &:hover {
          background-color: #f5f5f5;
        }
      }
    }


  }
}


.nav-bar .navbar-nav,.nav-bar .navbar-outer .navbar-nav{
	float:right;
	margin:0;
  width: auto;
  position: absolute;
  z-index: 5000;
  top: 0;
  right: 53px;

  &.navbar-right {
    top: 12px;
    right: 3px;
    font-size: 1.9em;

    li a {
      color: $color-white;
      background: none!important;

      &#signin-icon {
        width: 50px;
        height: 50px;
        margin-top: 5px;
        z-index: 1000;
        background-image: url('../../images/global/header-login.png')!important;
      }
    }
  }
}


// div.header div#mxHeaderleft .navbar-brand {
//
//   img.large-logo {
//     display: block!important;
//     margin-top: 5px;
//   }
//
// }



} // Close #p11-intero-container

.custom-footer .fl-module-content {
  padding: .5em .125em;
}

.top-promo-bar {
	background: $color-primary;
	padding: 1.5rem 1rem;
	text-align: left;
	display: none;
  position: relative;
	@include mq-sm {
		text-align: left;
		padding: 1rem 0;
	}

  .wrapper {
    padding: 0 3em 0 0;
  }

  h6 {
    font-weight: 700;
  }

	h6, p {
		color: $color-white;
		font-size: 1rem;
		margin: 0 0 0.75em 0;
		font-family: $font-main;
		line-height: 1.25rem;
		@include mq-sm {
			font-size: 1rem;
			margin: 0 4em 0 0;
		}
		a {
			color: $color-white;
			font-size: 1.1rem;
			@include mq-sm {
				font-size: 1rem;
			}
			.link-text {
				font-family: $font-main-bold;
			}
		}
		&:hover {
			a {
				color: lighten($color-tertiary, 15%);
			}
		}
	}

  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
	#hamburger,
  #hamburger-menu-item {
  	width: 30px;
  	height: 30px;
  	margin: 0;
  	position: absolute;
		right: 20px;
		top: 25px;
		cursor: pointer;

  	@include mq-md {
  		// margin-top: 15px;
		}

  	span {
  		display: block;
  		position: absolute;
  		height: 3px;
  		width: 100%;
  		background: $color-white;
  		opacity: 1;
  		left: 0;
  		-webkit-transform: rotate(0deg);
  		-moz-transform: rotate(0deg);
  		-o-transform: rotate(0deg);
  		transform: rotate(0deg);
  		-webkit-transition: 250ms ease-in-out;
  		-moz-transition: 250ms ease-in-out;
  		-o-transition: 250ms ease-in-out;
  		transition: 250ms ease-in-out;
  		&:nth-child(1) {
  			top: 0px;
  		}
  		&:nth-child(2),
  		&:nth-child(3) {
  			top: 8px;
  		}
  		&:nth-child(4) {
  			top: 16px;
  		}
		}

		&:hover > span {
			color: lighten($color-tertiary, 15%);
		}

  	&.open {
  		// span {
  		// 	background: $color-secondary;
  		// }
  		span:nth-child(1) {
  			top: 8px;
  			width: 0%;
  			left: 50%;
  		}
  		span:nth-child(2) {
  			-webkit-transform: rotate(45deg);
  			-moz-transform: rotate(45deg);
  			-o-transform: rotate(45deg);
  			transform: rotate(45deg);
  		}
  		span:nth-child(3) {
  			-webkit-transform: rotate(-45deg);
  			-moz-transform: rotate(-45deg);
  			-o-transform: rotate(-45deg);
  			transform: rotate(-45deg);
  		}
  		span:nth-child(4) {
  			top: 8px;
  			width: 0%;
  			left: 50%;
  		}
  	}
  }
}
