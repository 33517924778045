/*
       _ _                     _   _
 _ __ / / | ___ _ __ ___  __ _| |_(_)_   _____
| '_ \| | |/ __| '__/ _ \/ _` | __| \ \ / / _ \
| |_) | | | (__| | |  __/ (_| | |_| |\ V /  __/
| .__/|_|_|\___|_|  \___|\__,_|\__|_| \_/ \___|
|_|

Site design by p11|creative
Visit us @ p11.com

/*
|--------------------------------------------------------------------------
| Grid
|--------------------------------------------------------------------------
|
| This file holds all of the grid / structure related styles for the site.
|
*/


.full {
  width: 100%;
}

.half {
	width: 50%;
	@include mq-md {
		width: 100%;
		&.md-half {
			width: 50%;
		}
	}
	@include mq-sm {
		width: 100%;
		&.md-half {
			width: 100%;
		}
		&.sm-half {
			width: 50%;
		}
	}
}

.third {
	width: 33.33333333%; /* IE Fallback */
	width: calc(100% / 3);
	@include mq-md {
		width: 100%;
		&.md-third {
			width: calc(100% / 3);
		}
	}
	@include mq-sm {
		width: 100%;
		&.md-third {
			width: 100%;
		}
		&.sm-third {
			width: calc(100% / 3);
		}
	}
}

.twothird,
.two-third {
	width: 66.66%; /* IE Fallback */
	width: calc((100% / 3) * 2);
	@include mq-md {
		width: 100%;
		&.md-twothird,
		&.md-two-third {
			width: calc((100% / 3) * 2);
		}
	}
	@include mq-sm {
		width: 100%;
		&.md-twothird,
		&.md-two-third {
			width: 100%;
		}
		&.sm-twothird,
		&.sm-two-third {
			width: calc((100% / 3) * 2);
		}
	}
}

.fourth {
	width: 25%;
	@include mq-md {
		width: 100%;
		&.md-fourth,
		&.md-fourth {
			width: 25%;
		}
	}
	@include mq-sm {
		width: 100%;
		&.md-fourth,
		&.md-fourth {
			width: 100%;
		}
		&.sm-fourth,
		&.sm-fourth {
			width: 25%;
		}
	}
}

.twofourth,
.two-fourth {
	width: 50%;
	@include mq-md {
		width: 100%;
		&.md-fourth,
		&.md-fourth {
			width: 50%;
		}
	}
	@include mq-sm {
		width: 100%;
		&.md-fourth,
		&.md-fourth {
			width: 100%;
		}
		&.sm-fourth,
		&.sm-fourth {
			width: 50%;
		}
	}
}

.threefourth,
.three-fourth {
	width: 75%;
	@include mq-md {
		width: 100%;
		&.md-threefourth,
		&.md-three-fourth {
			width: 75%;
		}
	}
	@include mq-sm {
		width: 100%;
		&.md-threefourth,
		&.md-three-fourth {
			width: 100%;
		}
		&.sm-threefourth,
		&.sm-threefourth {
			width: 25%;
		}
	}
}

.fifth {
	width: 20%;
	@include mq-md {
		width: 100%;
		&.md-fifth,
		&.md-fifth {
			width: 20%;
		}
	}
	@include mq-sm {
		width: 100%;
		&.md-fifth,
		&.md-fifth {
			width: 100%;
		}
		&.sm-fifth,
		&.sm-fifth {
			width: 20%;
		}
	}
}

.twofifth,
.two-fifth {
	width: 40%;
	@include mq-md {
		width: 100%;
		&.md-twofifth,
		&.md-two-fifth {
			width: 40%;
		}
	}
	@include mq-sm {
		width: 100%;
		&.md-twofifth,
		&.md-two-fifth {
			width: 100%;
		}
		&.sm-twofifth,
		&.sm-two-fifth {
			width: 40%;
		}
	}
}

.threefifth,
.three-fifth {
	width: 60%;
	@include mq-md {
		width: 100%;
		&.md-threefifth,
		&.md-three-fifth {
			width: 60%;
		}
	}
	@include mq-sm {
		width: 100%;
		&.md-threefifth,
		&.md-three-fifth {
			width: 100%;
		}
		&.sm-threefifth,
		&.sm-three-fifth {
			width: 60%;
		}
	}
}

.fourfifth,
.four-fifth {
	width: 80%;
	@include mq-md {
		width: 100%;
		&.md-fourfifth,
		&.md-four-fifth {
			width: 80%;
		}
	}
	@include mq-sm {
		width: 100%;
		&.md-fourfifth,
		&.md-four-fifth {
			width: 100%;
		}
		&.sm-fourfifth,
		&.sm-four-fifth {
			width: 80%;
		}
	}
}

.sixth {
	width: 16.66666666; /* IE Fallback */
	width: calc(100% / 6);
	@include mq-md {
		width: 100%;
		&.md-sixth,
		&.md-sixth {
			width: 16%;
			width: calc(100% / 6);
		}
	}
	@include mq-sm {
		width: 100%;
		&.md-sixth,
		&.md-sixth {
			width: 100%;
		}
		&.sm-sixth,
		&.sm-sixth {
			width: 16%;
			width: calc(100% / 6);
		}
	}
}

.twosixth,
.two-sixth {
	width: 33.33333333; /* IE Fallback */
	width: calc((100% / 6) * 2);
	@include mq-md {
		width: 100%;
		&.md-twosixth,
		&.md-two-sixth {
			width: 33.33333333; /* IE Fallback */
			width: calc((100% / 6) * 2);
		}
	}
	@include mq-sm {
		width: 100%;
		&.md-twosixth,
		&.md-two-sixth {
			width: 100%;
		}
		&.sm-twosixth,
		&.sm-two-sixth {
			width: 33.33333333; /* IE Fallback */
			width: calc((100% / 6) * 2);
		}
	}
}

.threesixth,
.three-sixth {
	width: 50%;
	@include mq-md {
		width: 100%;
		&.md-threesixth,
		&.md-three-sixth {
			width: 50%;
		}
	}
	@include mq-sm {
		width: 100%;
		&.md-threesixth,
		&.md-three-sixth {
			width: 100%;
		}
		&.sm-threesixth,
		&.sm-three-sixth {
			width: 50%;
		}
	}
}

.foursixth,
.four-sixth {
	width: 66.66666666; /* IE Fallback */
	width: calc((100% / 6) * 4);
	@include mq-md {
		width: 100%;
		&.md-foursixth,
		&.md-four-sixth {
			width: 66.66666666%;
			width: calc((100% / 6) * 4);
		}
	}
	@include mq-sm {
		width: 100%;
		&.md-foursixth,
		&.md-four-sixth {
			width: 100%;
		}
		&.sm-foursixth,
		&.sm-four-sixth {
			width: 66.66666666%;
			width: calc((100% / 6) * 4);
		}
	}
}

.fivesixth,
.five-sixth {
	width: 83.33333333; /* IE Fallback */
	width: calc((100% / 6) * 5);
	@include mq-md {
		width: 100%;
		&.md-fivesixth,
		&.md-five-sixth {
			width: 83.33333333%;
			width: calc((100% / 6) * 5);
		}
	}
	@include mq-sm {
		width: 100%;
		&.md-fivesixth,
		&.md-five-sixth {
			width: 100%;
		}
		&.sm-fivesixth,
		&.sm-five-sixth {
			width: 83.33333333%;
			width: calc((100% / 6) * 5);
		}
	}
}

/**** Gutters ***/

.half-gutter1 {
	.half {
		width: calc(50% - 1em);
	}
	.half:first-of-type {
		margin-right: 1em;
	}
	.half:last-of-type {
		margin-left: 1em;
	}
}

.half-gutter2 {
	.half {
		width: calc(50% - 2em);
	}
	.half:first-of-type {
		margin-right: 2em;
	}
	.half:last-of-type {
		margin-left: 2em;
	}
}

.third-gutter1 {
	.third {
		width: calc(100% / 3 - 0.67em);
		margin: 1em 0 0;
		&:nth-child(3n + 2) {
			margin: 1em 1em 0;
		}
		@include mq-lg {
			width: calc(100% / 2 - 0.5em);
			&:nth-of-type(n) {
				margin: 1em 0 0 0;
			}
			&:nth-child(2n + 1) {
				margin-right: 1em;
			}
		}
		@include mq-md {
			width: calc(100%);
			&:nth-of-type(n) {
				margin: 1em 0 0 0;
			}
		}
	}
}

.left {
	float: left;
}

.right {
	float: right;
}

.center {
	margin: 0 auto;
}

.clearfix:before,
.clearfix:after {
	content: ' '; /* 1 */
	display: table; /* 2 */
}

.clearfix:after {
	clear: both;
}

.clearfix {
	*zoom: 1;
}

.fill {
	position: absolute;
	top: 0px;
	left: 0px;
	height: 100%;
	width: 100%;
	z-index: 200;
}

/*~~ TEXT ALIGN ~~*/

.tleft,
.text-left {
	text-align: left;
}

.tright,
.text-right {
	text-align: right;
}

.tcenter,
.text-center {
	text-align: center;
}

/*~~ PADDING ~~*/

.no-pad,
.nopad,
.pad0 {
	padding: 0;
}

.pad-half {
	padding: 0.5em;
}

.pad1-0 {
	padding: 1em 0;
}

.pad2-0 {
	padding: 2em 0;
}

.pad3-0 {
	padding: 3em 0;
}

.pad4-0 {
	padding: 4em 0;
}

.pad0-1 {
	padding: 0 1em;
}

.pad0-2 {
	padding: 0 2em;
}

.pad0-3 {
	padding: 0 3em;
}

.pad0-4 {
	padding: 0 4em;
}

.pad1-2 {
	padding: 1em 2em;
}

.pad1-3 {
	padding: 1em 3em;
}

.pad1-4 {
	padding: 1em 4em;
}

.pad2-1 {
	padding: 2em 1em;
}

.pad2-3 {
	padding: 2em 3em;
}

.pad2-4 {
	padding: 2em 4em;
}

.pad3-1 {
	padding: 3em 1em;
}

.pad3-2 {
	padding: 3em 2em;
}

.pad3-4 {
	padding: 3em 4em;
}

.pad4-1 {
	padding: 4em 1em;
}

.pad4-2 {
	padding: 4em 2em;
}

.pad4-3 {
	padding: 4em 3em;
}

.pad1 {
	padding: 1em;
}

.pad2 {
	padding: 2em;
}

.pad3 {
	padding: 3em;
}

.pad4 {
	padding: 4em;
}

/*~~ PADDING  TOP~~*/

.padt1 {
	padding-top: 1em;
}

.padt2 {
	padding-top: 2em;
}

.padt3 {
	padding-top: 3em;
}

.padt4 {
	padding-top: 4em;
}

.padl1 {
	padding-left: 1em;
	@include mq-sm {
		padding-left: 0;
	}
}

/*~~ PADDING BOTTOM ~~*/

.padb1 {
	padding-bottom: 1em;
}

.padb2 {
	padding-bottom: 2em;
}

.padb3 {
	padding-bottom: 3em;
}

.padb4 {
	padding-bottom: 4em;
}

/*~~ MARGIN ~~*/

.no-mar,
.nomar,
.nomarg,
.mar0 {
	margin: 0;
}

.mar-half {
	margin: 0.5em;
}

.mar1-0 {
	margin: 1em 0;
}

.mar2-0 {
	margin: 2em 0;
}

.mar3-0 {
	margin: 2em 0;
}

.mar4-0 {
	margin: 4em 0;
}

.mar0-1 {
	margin: 0 1em;
}

.mar0-2 {
	margin: 0 2em;
}

.mar0-3 {
	margin: 0 3em;
}

.mar0-4 {
	margin: 0 4em;
}

.mar1-2 {
	margin: 1em 2em;
}

.mar1-3 {
	margin: 1em 3em;
}

.mar1-4 {
	margin: 1em 4em;
}

.mar2-1 {
	margin: 2em 1em;
}

.mar2-3 {
	margin: 2em 3em;
}

.mar2-4 {
	margin: 2em 4em;
}

.mar3-1 {
	margin: 3em 1em;
}

.mar3-2 {
	margin: 3em 2em;
}

.mar3-4 {
	margin: 3em 4em;
}

.mar4-1 {
	margin: 4em 1em;
}

.mar4-2 {
	margin: 4em 2em;
}

.mar4-3 {
	margin: 4em 3em;
}

.mar1 {
	margin: 1em;
}

.mar2 {
	margin: 2em;
}

.mar3 {
	margin: 3em;
}

.mar4 {
	margin: 4em;
}

/*~~ margin  TOP~~*/

.mart1 {
	margin-top: 1em;
}

.mart2 {
	margin-top: 2em;
}

.mart3 {
	margin-top: 3em;
}

.mart4 {
	margin-top: 4em;
}

/*~~ margin BOTTOM ~~*/

.marb1 {
	margin-bottom: 1em;
}

.marb2 {
	margin-bottom: 2em;
}

.marb3 {
	margin-bottom: 3em;
}

.marb4 {
	margin-bottom: 4em;
}

/*~~ GLOBAL ~~*/

sup {
	vertical-align: super;
	font-size: 50%;
}

.center {
	margin: 0 auto;
}

.upper {
	text-transform: uppercase!important;
}

.lower {
	text-transform: lowercase;
}

.hidden, .hideme {
	display: none;
}

.noscroll,
.no-scroll {
	height: 100%;
	overflow: hidden;
}

.figure {
	overflow: hidden;
	position: relative;
}

.figure img,
.stretch {
	width: 100%;
	height: auto;
}

.relative {
  position: relative;
}

.cover {
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

.fixedbg,
.fixed-bg {
	background-attachment: fixed;
	will-change: top;
}

.css-table {
	display: table;
	.css-col {
		display: table-cell;
	}
}
