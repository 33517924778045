/*******************************************************
 BASIC FORM SCSS
********************************************************/

#interest {
	margin: 8em 0 0;

	@include mq-md-lg {
	  margin: 3em 0 0;
	}

	@include mq-tablet-landscape {
		margin: 3em 0 0;
	}

	.smalltype {
		width: 80%;
		margin: 0 auto;
		padding: 6em 0 1em;
		p {
			font-size: .75em;
			margin-bottom: 1em;
		}

		@include mq-md {
		  width: 100%;
			padding: 2em;
		}
	}

  .interest-holder {
		width: 80%;
		margin: 0 auto;
    padding: 6em;

		@include mq-md {
			width: 100%;
	    padding: 3em;
	  }
  }




  h2 {
		font-size: 2.25em;
    margin-bottom: .5em;

		@include mq-lg {
		  font-size: 1.75em;
		}
  }
}

#interest-list {
	:invalid,
	:-moz-submit-invalid,
	:-moz-ui-invalid {
		box-shadow: none;
	}
	display: block;
	width: 100%;
	color: $color-white;

	@include mq-md {
	  padding: 0;
	}

	.half,
	.fourth {
		@include mq-lg {
			width: 100% !important;
		}
	}

	div {
		&.fielditem {
			position: relative;
			padding: 0 0 2em;

			@include mq-lg {
			  padding: 0 0 1.5em;
			}
			&.fieldlist {
				padding-bottom: 0;
			}

			&.focused {
				label {
					transform: translateY(-125%);
					font-size: 0.75em;
					left: 5px;
				}
			}
		}
		// Moving Label
		&.fieldinput {
			label {
				color: $color-white;
				position: absolute;
				left: 0;
				bottom: 45%;
				font-size: 1em;
				padding: 0;
				@include transition(all 200ms ease);
			}
			&.focused {
				label {
					transform: translateY(-225%);
					font-size: 0.75em;
					// left: 5px;
				}
				input {
					border-bottom: 1px solid $color-white;
				}
				&#comments-input {
					label {
						transform: translateY(-100%);
					}
				}
			}

			&#comments-input {
				label {
					bottom: auto;
					top: 10%;
				}
				&.focused {
					label {
						transform: translateY(-200%);
						font-size: 0.75em;
						// left: 5px;
					}
				}
			}

		}
		&#sec1,
		&#sec2,
		&#sec3 {
			padding: 0;
		}
	}
	label {
		color: $color-white;
		font-size: 1em;
		line-height: 1.25em;
		padding: 0.5 0.75em;
		z-index: 10;
		@include transition(all 200ms ease);
		&.full {
			width: 10%;
			display: block;
		}
	}
	input:-webkit-autofill {
		//   -webkit-box-shadow: inset 0 0 0px 9999px white;
	}
	input {
		&[type='text'],
		&[type='email'] {
			width: 100%;
			position: relative;
			background: none;
			color: $color-white;
			padding: 0.75em 0.25em;
			letter-spacing: 0.5px;
			border: none;
			border-bottom: 1px solid $color-white;
			font-size: 16px;
			@include transition(all 500ms ease);
			// height: 41px;
		}

	}
	//    select.required-highlight {
	//       background-color: #f4e0e0;
	//    }
	//    .required-highlight label {
	//       color: #ff8b8b !important;
	//    }
	//    .required-highlight input {
	//       background-color: #f4e0e0;
	//    }

	.required-highlight {
		.control__indicator {
			border: 1px solid #ff8b8b;
		}

		label.control {
			margin-left: 0;
		}
	}
	.select.required-highlight {
		select {
			color: #ff8b8b;
			border-bottom: 1px solid #ff8b8b;
		}
		.select__arrow {
			border-color: #ff8b8b transparent transparent transparent;
		}
	}
	.required-highlight label,
	label.required-highlight {
		color: #ff8b8b !important;
		margin-left: 10px;
	}
	.required-highlight input {
		color: $color-white;
		border-bottom: 1px solid #ff8b8b;
	}
	textarea {
		width: 100%;
		background: none;
		color: $color-white;
		border: none;
		border-bottom: 1px solid $color-white;
		padding: 0.75em 0.25em;
		letter-spacing: 0.5px;
		font-family: $font-main;
		font-size: 16px;
		resize: none;
		height: 7em;
		overflow: auto;
		@include transition(0.2s all ease-in-out);
		&:focus {
			border-color: $color-white;
		}
	}
}

/* CONTROL GROUP */

.control-group {
	padding: 2rem 2rem 0 1rem;
	.full {
		margin-bottom: 1em;
	}
	display: inline-block !important;
	vertical-align: top !important;
	text-align: left !important;
}

.control {
	display: block;
	position: relative;
	padding: 0 0 0 30px !important;
	margin-bottom: 0.9em !important;
	cursor: pointer;
	input {
		position: absolute;
		z-index: -1;
		opacity: 0;
	}
}

.control__indicator {
	position: absolute;
	top: -3px;
	left: 0;
	height: 20px;
	width: 20px;
	border: 1px solid $color-white;
}

.control--radio .control__indicator {
	border-radius: 50%;
}

.control {
	&:hover input ~ .control__indicator {
		background: none;
	}
	input {
		&:focus ~ .control__indicator {
			background: none;
		}
		&:checked ~ .control__indicator {
			background: $color-black!important;
		}
	}
	&:hover input:not([disabled]):checked ~ .control__indicator {
		background: none;
	}
	input {
		&:checked:focus ~ .control__indicator {
			background: none;
		}
		&:disabled ~ .control__indicator {
			background: #e6e6e6;
			opacity: 0.6;
			pointer-events: none;
		}
	}
}

.control__indicator:after {
	content: '';
	position: absolute;
	display: none;
}

.control input:checked ~ .control__indicator:after {
	display: block;
}

.control--checkbox {
	margin-left: 0 !important;
	.control__indicator:after {
		left: 7px;
		top: 3px;
		width: 5px;
		height: 10px;
		border: solid $color-white;
		border-width: 0 2px 2px 0;
		transform: rotate(45deg);
	}
	input:disabled ~ .control__indicator:after {
		border-color: #7b7b7b;
	}
}

.control--radio {
	.control__indicator:after {
		left: 6px;
		top: 6px;
		height: 6px;
		width: 6px;
		border-radius: 50%;
		background: $color-white;
	}
	input:disabled ~ .control__indicator:after {
		background: #7b7b7b;
	}
}

.select {
	position: relative;
	display: inline-block;
	width: 100%;

	select {
		display: inline-block;
		width: 100%;
		padding: 10px 15px;
		cursor: pointer;
		color: $color-white;
		border: 0;
		border-radius: 0;
		outline: 0;
		border-bottom: 1px solid $color-white;
		font-size: 1em;
		font-weight: 300;
		padding: 0.7em 0;
		letter-spacing: 0.5px;
		// margin-top: .25em;
		background: none;
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		height: 45px;
	}

	&.focused select {
		color: $color-white;
		border-color: 1px solid $color-tertiary;
	}

	&::-ms-expand {
		display: none;
	}

	&:hover,
	&:focus {
		// color: #000;
		// background: #ccc;
	}

	&:hover,
	&:focus {
		// color: #000;
		// background: #ccc;
	}

	&:disabled {
		pointer-events: none;
		opacity: 0.5;
	}

	.select__arrow {
		position: absolute;
		top: 16px;
		right: 15px;
		width: 0;
		height: 0;
		pointer-events: none;
		border-width: 8px 5px 0 5px;
		border-style: solid;
		border-color: $color-white transparent transparent transparent;
	}

	&:hover ~ .select__arrow,
	&:focus ~ .select__arrow {
		// border-top-color: #000;
	}

	&:disabled ~ .select__arrow {
		border-top-color: #ccc;
	}
}

#interest-list {

	// button[type='submit'] {
	// 	font-family: $font-main;
	// 	width: 250px;
	// 	height: 45px;
	// 	z-index: 400;
	// 	color: $color-white;
	// 	text-transform: uppercase;
	// 	position: relative;
	// 	margin: 1em auto 0;
	// 	font-size: 1.25em;
	// 	line-height: 1.5em;
	// 	padding: 0.35em;
	// 	background-color: $color-secondary;
	// 	@include transition(0.2s all ease-in-out);
	// 	&:hover {
	// 		background-color: $color-secondary-dark;
	// 		cursor: pointer;
	// 	}
	//
	// }


	button[type='submit'] {
	  text-transform: uppercase;
	  display: block;
	  font-size: 1.25em;
	  line-height: 1.75em;
	  text-align: center;
	  text-decoration: none;
	  margin: 0;
		width: 200px;
		margin: 0 auto;
	  padding: .35em;
	  position: relative;
	  color: $color-white;
	  border: 1px solid $color-white;
	  background-color: fn-transparent-color($color-white, 0);
	  @include transition(background-color 0.5s ease);

		@include mq-sm {
		  margin-top: 4em;
		}

	  &:hover {
	    background-color: fn-transparent-color($color-white, 0.05);
	  }
	}


	div {
		&#process,
		&#success {
			padding: 2em 0;
			color: $color-white;
		}
	}
}

#form-submit {
	width: 100%;
	float: right;
	padding: 0 1em;
	width: 100%;
	float: right;
	padding: 0;
	margin: 0 auto;
	text-align: center;
	position: relative;

	@include mq-lg {
	  text-align: left;
	}

	.required-text {
		position: absolute;
		right: 1em;
		top: .75em;
		display: inline-block;
		text-align: left;

		// @include mq-sm {
		// 	position: relative;
		// 	float: left;
		// 	top: auto;
		// 	margin-bottom: 1em;
		// 	width: 100%;
		// }
	}
}

#process h4,
#process .form-loader,
#success h4, {
	font-size: 2.25em;
	margin: 0 0 0.5em 0;
	padding: 0;
	text-align: center;
	color: $color-white;
}

#process p,
#success p {
	color: $color-white;
	text-align: center;
	margin: 0;
	padding: 0;
}

#errorchecking.alert {
	background-color: #ff8b8b;
	text-align: center;
	clear: both;
	padding: 1em;
	margin: 0.9em;
	margin-bottom: 1.5em;
	p {
		margin: 0;
		padding: 0;
		color: $color-white;
		text-transform: uppercase;
	}
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	border: none;
	// border-bottom: 1px solid $color-white!important;
	-webkit-text-fill-color: $color-white;
	-webkit-box-shadow: 0 0 0px 1000px $color-secondary inset!important;
	transition: background-color 5000s ease-in-out 0s;
}
